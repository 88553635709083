.ChildCard .child-setting {
  margin-bottom: 25px;
}
.ChildCard .child-setting div {
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  justify-content: space-between;
}
.ChildCard .content-container span {
  margin-left: 0;
}
.child-setting .setting-description {
  text-align: left;
  font-size: 0.7em;
  color: rgb(145, 145, 145);
}
.ChildCard .info {
  color: #6466b1;
}
.ChildCard .setting-slide {
  text-align: center;
}
.ChildCard .setting-slide span {
  display: block;
  /* text-align: center;
  margin: 10px auto; */
}
.setting-slide .setting-description {
  text-align: center;
}
