.GraphCard {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 13px #ccc;
  padding: 10px 25px;
  width: 80%;
  margin: 30px auto;
}
.graph-header h3 {
  font-weight: 600;
  color: rgb(49, 49, 49);
  font-size: 1.1em;
  margin: 10px 0 5px 0;
}
.graph-header p {
  color: rgb(85, 85, 85);
  font-size: 0.85em;
  margin: 5px 0 20px 0;
}
.graph-graph {
  width: 750px;
  height: 750px;
  margin: 20px auto;
}
.no-data-found {
  text-align: center;
  margin: 50px auto;
  color: gray;
}
.graph-options {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0px auto;
  /* border: 1px solid red; */
}
.graph-options div {
  text-align: center;
}
.graph-options input {
  margin-right: 7px;
  vertical-align: middle;
}
.graph-options label {
  font-size: 0.8em;
}

@media only screen and (max-width: 1050px) {
  .GraphCard {
    width: 90%;
  }
  .graph-graph {
    width: 600px;
    height: 600px;
  }
}
