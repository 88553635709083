.GameEntry {
  display: flex;
  justify-content: space-around;
  /* justify-content: space-between; */
  align-items: center;
  /* align-items: flex-end; */
  margin: 30px 0;
}
.GameEntry p {
  font-weight: 600;
  text-align: center;
  margin: 5px;
}
.GameEntry img {
  width: 70px;
}
.GameEntry .game-icon {
  flex-basis: 24%;
  text-align: center;
}
.GameEntry .game-hours {
  flex-basis: 22%;
}
.GameEntry .game-highest-score {
  flex-basis: 22%;
}
.game-icon a {
  text-decoration: none;
  color: #000;
}
