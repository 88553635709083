.Development .development-container {
  width: 70%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  margin-top: 30px;
  margin-bottom: 50px;
  position: relative;
  padding-bottom: 25px;
}
.Development .header {
  display: flex;
  justify-content: space-between;
  /* align-content: flex-start; */
}
.add-game {
  text-align: center;
  cursor: pointer;
  /* border: 1px solid red; */
}
.add-game p {
  font-size: 0.75em;
  margin: 2px;
}
.add-game svg {
  font-size: 1.2em;
}
.list-games {
  margin-top: 10px;
}
.list-games div {
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-around;
}
.list-header p {
  color: #3f37c9;
}
.list-games p {
  /* border: 1px solid blue; */
  flex-basis: 25%;
  text-align: center;
  font-weight: 500;
  font-size: 0.9em;
  vertical-align: center;
}
.list-header div,
.list-games svg {
  flex-basis: 6%;
  /* border: 1px solid red; */
}
.list-games svg {
  margin-top: 14px;
  cursor: pointer;
}
.list-content p {
  font-size: 0.8em;
}
.header-tabs {
  display: flex;
  background-color: #eeedf3;
  margin: -20px;
  margin-bottom: 12px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  /* border: 1px solid red; */
}
.header-tabs p {
  /* margin: 20px; */
  font-size: 0.95em;
  padding: 10px 25px;
  margin: 0;
  cursor: pointer;
  /* margin-left: 20px; */
}
.active-tab {
  font-weight: 600;
  background-color: #fff;
}
.games-tab {
  border-top-left-radius: 25px;
}
