.GamesCard {
  /* width: 90%; */
  /* display: none; */
  flex-basis: 70%;
  /* height: 670px; */
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  /* margin-top: 30px; */
  /* max-width: 1200px; */
  position: relative;
  padding-bottom: 25px;
  margin-left: 40px;
  /* margin: 30px auto; */
}
.games-header {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  font-weight: 600;
  text-align: center;
  margin: 30px auto;
}
.games-header p:nth-child(1) {
  flex-basis: 24%;
}
.games-header p:nth-child(2) {
  flex-basis: 22%;
}
.games-header p:nth-child(3) {
  flex-basis: 22%;
}
.games-options-container {
  position: relative;
  display: inline;
  /* width: 120px; */
  /* border: 1px solid black; */
}
