.RecentPlayersCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.recent-players {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 20px auto;
}
.recent-players a {
  text-decoration: none;
  color: #000;
}
.recent-player {
  /* width: 180px; */
  /* width: 90%; */
  /* border: 1px solid red; */
}
.recent-player .icon {
  border: 1.5px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  background-color: #4ac8eb;
  margin: 20px auto 10px auto;
  cursor: pointer;
}
.recent-player .name {
  text-align: center;
  font-size: 0.85em;
  margin: 0px;
  cursor: pointer;
}
.recent-player .hours-ago {
  text-align: center;
  font-size: 0.8em;
  color: gray;
  margin: 5px;
}
.RecentPlayersCard .footer {
  text-align: right;
  cursor: pointer;
  font-size: 0.9em;
}
.RecentPlayersCard .footer p {
  margin-bottom: 0;
}
.RecentPlayersCard .footer a {
  text-decoration: none;
  color: #000;
}
.RecentPlayersCard .footer img {
  width: 30px;
  vertical-align: middle;
}
