.PlayerOptionsMenu {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 13px #ccc;
  min-width: 140px;
  position: absolute;
  right: 0px;
  z-index: 10;
}
.PlayerOptionsMenu ul {
  padding: 0;
}
.PlayerOptionsMenu ul li {
  list-style: none;
  padding: 8px 10px;
  margin: 0 10px;
  font-size: 0.8em;
  color: #495057;
  cursor: pointer;
}
.PlayerOptionsMenu ul li svg {
  font-weight: lighter;
  margin-right: 8px;
}
.PlayerOptionsMenu ul li:hover {
  background-color: #f4f4f4;
  border-radius: 9px;
}
