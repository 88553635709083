.App {
  width: 100%;
  display: flex;
  background-color: #f3f6fd;
}
.App main {
  flex-basis: 100%;
  margin: 20px 20px 10px 40px;
  background-color: #f3f6fd;
}
.App .containers {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 40px;
  /* border: 1px solid green; */
}
.container-title {
  color: #42447d;
  font-size: 1.2em;
  font-weight: 500;
  margin: 0;
}
.container-subtitle {
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
}
.card {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 1px 1px 2px #e9e9e9, -1px -1px 2px #e9e9e9;
  padding: 20px;
}
