.PlayersCard {
  /* display: flex; */
  /* flex-direction: column; */
  width: 90%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  margin-top: 30px;
  margin-bottom: 50px;
  /* max-width: 1200px; */
  position: relative;
  padding-bottom: 25px;
  /* margin: 30px auto; */
}
.players-header {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  font-weight: 600;
  text-align: center;
  margin: 30px auto;
  color: #3f37c9;
  /* max-width: 1200px; */
  /* border: 1px solid red; */
}
.players-header p:nth-child(1) {
  flex-basis: 20%;
}
.players-header p:nth-child(2) {
  flex-basis: 28%;
}
.players-header p:nth-child(3) {
  flex-basis: 12%;
}
.players-list h2 {
  display: none;
}
.players-list .inactive-players:first-of-type h2 {
  display: block;
  font-size: 1.4em;
  margin: 90px 20px 40px 40px;
  /* border: 1px solid purple; */
}
.add-player {
  text-align: center;
  cursor: pointer;
  /* flex-basis: 10%; */
  /* border: 1px solid blue; */
}
.add-player svg {
  font-size: 1.5em;
  color: #3a0ca3;
  margin-bottom: 5px;
}
.add-player span {
  display: block;
  font-size: 0.8em;
}
