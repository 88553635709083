.UserCard {
  background-color: #fff;
  border-radius: 25px;
  padding: 20px;
  /* height: 700px; */
  flex-basis: 30%;
  max-width: 300px;
  box-shadow: 1px 1px 2px #e9e9e9, -1px -1px 2px #e9e9e9;
}
.UserCard .profile-pic {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #34dcb4;
  border: 1.5px solid #000;
}
.UserCard .header {
  display: flex;
  /* justify-content: space-evenly; */
  margin: 20px;
}
.UserCard .profile-info {
  /* border: 1px solid red; */
  margin-left: 15px;
  text-transform: capitalize;
}
.UserCard .profile-data {
  display: flex;
  justify-content: space-around;
}
.profile-data p {
  color: #3f37c9;
  font-weight: 600;
  font-size: 0.9em;
}
.profile-data span {
  font-size: 1.1em;
  font-weight: 600;
}
.profile-data div {
  text-align: center;
}
.UserCard h3 {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}
.UserCard h4 {
  font-size: 1em;
  color: #aaa;
  font-size: 0.9em;
  font-weight: 500;
  margin: 8px 0;
}
.UserCard .separator {
  background-color: #d2d2d2;
  width: 100%;
  height: 1px;
  display: block;
  margin: 40px auto;
}
.UserCard .subtitle {
  font-weight: 600;
  margin-bottom: 3px;
}
.UserCard .info {
  color: #7678a8;
  font-weight: 500;
  font-size: 0.9em;
}
.UserCard .content-container {
  margin: 20px 0;
  /* border: 1px solid red; */
}
.content-container div {
  margin: 10px 0;
}
.content-container span {
  font-size: 0.9em;
  color: #7678a8;
  margin-left: 8px;
}
.UserCard .profile-section {
  margin: 20px;
}
.profile-section ul {
  padding: 0;
}
.profile-section ul li {
  list-style: none;
  font-size: 0.9em;
  color: #777;
  margin: 10px 0;
}
.recent-date {
  font-size: 0.78em;
  color: gray;
}
.recent-username {
  color: #3f37c9;
}
.recent-title {
  /* font-weight: 500; */
  color: black;
  font-size: 0.8em;
}
