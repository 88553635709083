.Modal {
  /* display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(150, 150, 150, 0.3);
  /* z-index: 100; */
}
.Modal .modal-window {
  width: 500px;
  /* height: 500px; */
  background-color: #fff;
  box-shadow: 0 0 13px #ccc;
  border-radius: 15px;
  /* padding: 25px 25px 0 25px; */
  box-sizing: border-box;
}
.Modal .modal-header {
  display: flex;
  justify-content: space-between;
}
.modal-header h2 {
  font-size: 1.5em;
}
.modal-body {
  overflow-y: scroll;
  height: 300px;
  margin: 20px;
  padding: 10px;
  border: 1px dashed rgb(234, 224, 252);
}
.modal-body p {
  font-size: 0.85em;
}
.close-modal {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
  /* border: 1px solid rgb(119, 119, 119); */
  font-size: 0.9em;
  box-shadow: 0 0 6px rgb(182, 182, 182);
  cursor: pointer;
  background-color: #f6f5fa;
}
.close-modal img {
  width: 12px;
}
.Modal .modal-content {
  /* width: 100%; */
  /* margin: 10px auto 0; */
  margin: 35px 25px 0 25px;
  /* border: 1px solid red; */
}
.modal-footer {
  background-color: #f8f7fb;
  margin-bottom: 0;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.modal-footer button {
  padding: 12px 20px;
  margin: 20px;
  border-radius: 7px;
  border: none;
  box-shadow: 0 0 3px #ccc;
  cursor: pointer;
}
.hasScrolled {
  background-color: #552cf6;
  color: #fff;
}
#cancel-btn {
  background-color: #fff;
}
