.PlayerEntry {
  display: flex;
  justify-content: space-around;
  /* justify-content: space-between; */
  align-items: center;
  /* align-items: flex-end; */
  margin: 30px 0;
  /* border: 1px solid red; */
}
.player-name {
  display: flex;
  /* flex-direction: row; */
  flex-basis: 20%;
  margin-left: 20px;
  cursor: pointer;
  /* border: 1px solid red; */
}
.player-name a {
  display: flex;
  color: #000;
  /* align-items: flex-end; */
  text-decoration: none;
}
.player-name .player-icon {
  font-size: 1.4em;
  font-weight: 500;
  border: 1.5px solid black;
  background-color: #4ac8eb;
  border-radius: 50%;
  min-width: 50px;
  height: 50px;
  display: flex;
  margin: 0 13px 0 0;
  justify-content: center;
  align-items: center;
}
.player-name p {
  font-weight: 500;
  /* margin-bottom: 2px; */
}
.played-time {
  color: #a1a1a1;
  font-size: 0.9em;
  text-align: center;
}
.player-progress {
  flex-basis: 30%;
}
.player-progress .bar {
  width: 90%;
  height: 10px;
  background-color: #c4c4c4;
  border-radius: 25px;
}
.player-progress .bar span {
  display: block;
  height: 10px;
  border-radius: 25px;
  background-color: #021d7d;
}
.player-hours {
  text-align: center;
  font-size: 0.9em;
  flex-basis: 10%;
  /* border: 1px solid red; */
}
.player-options-container {
  /* flex-basis: 10%; */
  width: 100px;
  position: relative;
  display: inline;
}
.player-options {
  /* border: 1px solid blue; */
  text-align: center;
}
.player-options svg {
  color: #021d7d;
  font-size: 1.1em;
  cursor: pointer;
}
.deactive {
  opacity: 0.5;
}
