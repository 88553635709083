@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@300;400;500;600&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  overscroll-behavior-y: none; /* disables overscrolling apple devices */
  /* background-color: #f3f6fd; */
}
