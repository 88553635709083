.SharePlayerModal .modal-window {
  position: relative;
}
.share-info {
  margin-top: 0;
}
.share-info span {
  color: #552cf6;
  font-weight: 600;
}
.SharePlayerModal .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.9);
}
.SharePlayerModal .message {
  font-size: 0.8em;
  color: #eb2678;
}
.SharePlayerModal .modal-content .field {
  margin-bottom: 12px;
}
.is-successful {
  display: fixed;
  text-align: center;
  padding-top: 50px;
}
.is-successful svg {
  color: rgb(20, 199, 20);
  font-size: 3em;
}

.disclaimer{
  font-weight: 400;
  text-align: center;
}
