.player-content {
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  /* justify-content: space-around; */
  /* align-items: center; */
  margin-top: 40px;
  /* border: 1px solid red; */
}
.player-content .games-sessions {
  flex-basis: 70%;
}
