.LevelsCard p {
  display: inline-block;
  margin-right: 15px;
  /* text-decoration: underline; */
  font-size: 0.85em;
  cursor: pointer;
  color: #3f37c9;
}
.LevelsCard p:hover {
  text-decoration: underline;
}
.LevelsCard {
  margin: 15px auto;
  text-align: center;
}
.level-active {
  text-decoration: underline;
}
