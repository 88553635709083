.Navigation {
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-basis: 25%;
  max-width: 220px;
  height: 100vh;
  /* border: 1px solid blue; */
  /* padding-left: 10px; */
  /* background-color: #fff; */
}
.nav-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  /* border: 1px solid red; */
  border-right: 1px solid #bbbbbb;
  background-color: #fff;
  width: 25%;
  margin-left: 0;
  height: 100vh;
  max-width: 220px;
}
.Navigation .header {
  margin: 10px 0 0 15px;
  text-align: center;
}
.header {
  flex-basis: 10%;
}
.header h1 {
  margin-bottom: 0px;
  font-size: 1.25em;
  margin: 0;
  font-family: "Baloo 2", sans-serif;
  font-weight: 300;
}
.header h2 {
  margin-top: 0px;
  font-size: 1em;
  font-weight: 500;
  margin-left: 20px;
}
.header img {
  width: 180px;
}
.Navigation nav {
  flex-basis: 78%;
}
.Navigation ul li {
  list-style: none;
  margin: 33% auto;
  /* border: 1px solid red; */
}
.Navigation svg {
  font-size: 1.5em;
  /* vertical-align: middle; */
}
.Navigation ul li a {
  text-decoration: none;
  color: black;
  font-weight: 600;
}
.Navigation .logout {
  padding-left: 40px; /*to align with ul default padding */
  cursor: pointer;
}

/* NOT BEING USED */
.Navigation ul li img {
  vertical-align: middle;
  width: 125px;
  margin-right: 15px;
}

@media only screen and (max-width: 1250px) {
  .Navigation {
    flex-basis: 85px;
  }
  .nav-container {
    width: 85px;
    text-align: center;
  }
  .header h1 {
    font-size: 0.95em;
    margin-left: 2px;
  }
  .Navigation .header h1 {
    display: none;
  }
  .header h2 {
    font-size: 0.7em;
    margin-left: 2px;
  }
  .Navigation nav {
    text-align: center;
    margin: 0 auto;
  }
  .Navigation ul {
    padding: 0;
  }
  .Navigation ul li a span {
    display: none;
  }
  .Navigation ul li {
    margin: 60px auto;
  }
  .Navigation svg {
    font-size: 1.5em;
    margin-right: 0 !important;
    float: none;
  }
  .Navigation .logout {
    padding-left: 0;
  }
  .logout span {
    display: none;
  }
  .header img {
    width: 60px;
  }
}
