.SessionsCard {
  flex-basis: 50%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  /* margin-top: 30px; */
  /* max-width: 1200px; */
  position: relative;
  padding-bottom: 25px;
  /* margin-left: 40px; */
  margin-top: 20px;
}
.SessionsCard .sessions-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.session-date {
  font-size: 0.95em;
  color: #535353;
}
.sessions-header {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  font-weight: 600;
  text-align: center;
  margin: 30px auto;
}
.sessions-header p {
  flex-basis: 33%;
}
.SessionsCard .footer img {
  width: 30px;
  vertical-align: middle;
  /* transform: rotate(90deg); */
}
.SessionsCard .footer {
  text-align: right;
  cursor: pointer;
  font-size: 0.9em;
}
.SessionsCard .footer p {
  margin-bottom: 0;
}
