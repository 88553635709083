.Dashboard {
  /* background-color: #f3f6fd; */
}
.Dashboard h1 {
  font-weight: 600;
  color: #42447d;
  margin: 0;
  font-size: 1.6em;
}
.Dashboard h2 {
  font-weight: 500;
  font-size: 0.9em;
  margin: 0;
}
.Dashboard .data-containers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 70%;
  margin-left: 40px;
}
