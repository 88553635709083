.return-login a {
  text-decoration: none;
  color: #4361ee;
  font-size: 0.9em;
  font-weight: 500;
}
.return-login {
  margin: 30px 0 0 0;
}
.ResetPassword .form-content {
  margin-bottom: 0;
}
