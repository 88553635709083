.Form {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
  height: 100vh;
  /* padding: 20px; */
  /* border: 1px solid red; */
}
.Form .header h1,
.Form .header h2 {
  margin-left: 0;
}
.Form .header {
  font-size: 1.4em;
  margin-top: 20px;
  font-family: "Baloo 2", sans-serif;
  font-weight: 300;
}
.Form .header #logo {
  vertical-align: middle;
}
.Form .form-title {
  font-weight: 600;
  font-size: 1.5em;
  /* color: #3c4257; */
  color: #23395b;
  margin-top: 10px;
  margin-left: 20px;
}
.Form .form {
  margin: 50px 0;
}
.Form .main {
  /* width: 420px; */
  margin: 0px auto;
}
.no-account-signup {
  font-size: 0.8em;
  color: #3c4257;
}
.no-account-signup a {
  color: #4361ee;
  text-decoration: none;
}
.Form .form-container {
  width: 420px;
  margin: 20px auto;
  padding: 30px 20px;
  /* box-shadow: 2px 2px 2px grey; */
  box-shadow: 0 0 13px #ccc;
}
.form-content {
  width: 380px;
  text-align: center;
  margin: 20px auto;
}
.form-content form div {
  text-align: left;
}
.form-container form label {
  color: #3c4257;
  font-size: 0.9em;
  font-weight: 600;
}
.form-container form input {
  border: 1px solid #e0e1e4;
  border-radius: 6px;
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: #3c4257;
  padding: 11px;
  width: 350px;
  margin-top: 10px;
}
.form-container .field {
  margin: 30px auto;
}
.form-content form button {
  width: 380px;
  padding: 10px;
  border-radius: 6px;
  font-size: 0.9em;
  font-weight: 600;
  /* background-color: #635bff; */
  background-color: #3a0ca3;
  border: none;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.form-content .forgot-password a {
  font-size: 0.8em;
  text-decoration: none;
  display: inline-block;
  margin-top: 12px;
  /* color: #635bff; */
  color: #4361ee;
  font-weight: 500;
}
.Form .footer {
  text-align: center;
  margin-bottom: 20px;
}
.Form .footer a {
  text-decoration: none;
  font-size: 0.9em;
  color: #3c4257;
}
.password-msg {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 0.76em;
  color: #3c4257;
  opacity: 0;
}
.reset-msg {
  text-align: left;
  color: #3c4257;
  font-size: 0.9em;
  /* font-weight: 500; */
}
.show-msg {
  opacity: 1;
}
.show-msg-warning {
  color: red;
  opacity: 1;
}
.form .form-container .confirm-password {
  margin-top: -12px;
}
.show-confirm-msg {
  opacity: 1;
}
.show-confirm-warning {
  color: red;
  opacity: 1;
}
.server-response {
  color: red;
  font-size: 0.75em;
}
