.DevelopmentSetupModal input[type="text"] {
  padding: 12px 11px;
}
.DevelopmentSetupModal .field-path {
  font-size: 0.9em;
  margin-top: 25px;
}
.DevelopmentSetupModal .modal-window {
  width: 600px;
}
.DevelopmentSetupModal .field > div {
  display: block;
}
.file-type {
  display: block;
  font-size: 0.75em;
  color: grey;
}
.DevelopmentSetupModal .modal-content input {
  margin-top: 5px;
}
.DevelopmentSetupModal .modal-content .field {
  margin: 5px 0 30px;
}
