.ViewHeader {
  display: flex;
  justify-content: space-between;
}
.ViewHeader h1 {
  font-weight: 600;
  color: #42447d;
  margin: 0;
  font-size: 1.6em;
}
.ViewHeader h2 {
  font-weight: 500;
  font-size: 0.9em;
  margin: 0;
  text-transform: capitalize;
}
.download-app {
  text-align: center;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.download-app svg {
  font-size: 1.5em;
  color: #3f37c9;
  /* color: #42447d; */
  /* color: #34dcb4; */
}
.download-app p {
  margin: 2px 0 0 0;
  font-size: 0.75em;
  font-weight: 500;
  color: #000;
}
.download-app a {
  text-decoration: none;
}
