.SessionEntry {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin: 10px auto; */
  /* border: 1px solid red; */
  text-align: center;
}
.SessionEntry p,
.SessionEntry a {
  flex-basis: 33%;
  text-decoration: none;
}
.SessionEntry a:hover {
  text-decoration: underline;
}
.session-id {
  /* text-decoration: underline; */
  cursor: pointer;
  font-weight: 500;
  color: #3f37c9;
  /* text-align: left; */
}
