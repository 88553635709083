.AddPlayer {
  /* display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(150, 150, 150, 0.3);
}
.AddPlayer .modal-window {
  width: 400px;
  /* height: 500px; */
  background-color: #fff;
  box-shadow: 0 0 13px #ccc;
  border-radius: 15px;
  /* padding: 25px 25px 0 25px; */
  box-sizing: border-box;
}
.modal-header {
  display: flex;
  justify-content: space-between;
}
.close-modal {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
  /* border: 1px solid rgb(119, 119, 119); */
  font-size: 0.9em;
  box-shadow: 0 0 6px rgb(182, 182, 182);
  cursor: pointer;
  background-color: #f6f5fa;
}
.close-modal img {
  width: 12px;
}
.AddPlayer .modal-content {
  /* width: 100%; */
  /* margin: 10px auto 0; */
  margin: 35px 25px 0 25px;
  /* border: 1px solid red; */
}
.modal-content .field {
  margin: 20px 0 30px;
}
.AddPlayer .modal-content label {
  color: #3c4257;
  font-size: 0.9em;
  font-weight: 600;
}
.username-container {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  /* border: 1px solid red; */
  margin-bottom: -28px;
}
.generate-label {
  font-size: 0.75em;
  text-decoration: underline;
  color: rgb(23, 196, 23);
  cursor: pointer;
}
.AddPlayer .modal-content input {
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: #3c4257;
  padding: 11px;
  width: 324px;
  /* width: 100%; */
  max-width: 100%;
  margin-top: 10px;
}
.modal-footer {
  background-color: #f8f7fb;
  margin-bottom: 0;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.modal-footer button {
  padding: 12px 20px;
  margin: 20px;
  border-radius: 7px;
  border: none;
  box-shadow: 0 0 3px #ccc;
  cursor: pointer;
}
#create-btn {
  background-color: #552cf6;
  color: #fff;
}
#cancel-btn {
  background-color: #fff;
}
