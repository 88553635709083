.SmallCard {
  width: 240px;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  /* box-shadow: 2px 2px 2px gray; */
  box-shadow: 0 0 13px #ccc;
  font-weight: 500;
}
.SmallCard p {
  margin: 5px;
  font-size: 0.9em;
}
.SmallCard .data {
  font-size: 2.1em;
  display: block;
  text-align: center;
}
.SmallCard .footer {
  font-size: 0.8em;
  margin-left: 10px;
}
.SmallCard .good {
  color: rgb(22, 185, 22);
}
.SmallCard .bad {
  color: rgb(226, 94, 17);
}
