.Toggle span {
  color: rgb(173, 173, 173);
  margin-left: 0;
  font-size: 0.8em;
}
.toggle {
  /* padding: 10px; */
  /* width: 55px; */
  margin-top: 20px;
  border-radius: 5px;
  background: white;
}
.toggle input.mobileToggle {
  opacity: 0;
  position: absolute;
}
.toggle input.mobileToggle + label {
  position: relative;
  display: inline-block;
  user-select: none;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-tap-highlight-color: transparent;
  height: 20px;
  width: 40px;
  border: 1px solid #e4e4e4;
  border-radius: 60px;
}
.toggle input.mobileToggle + label:before {
  content: "";
  position: absolute;
  display: block;
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 41px;
  top: 0;
  left: 0;
  border-radius: 30px;
}
.toggle input.mobileToggle + label:after {
  content: "";
  position: absolute;
  display: block;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f5f5f5;
  height: 22px;
  width: 22px;
  top: -1px;
  left: 0px;
  border-radius: 60px;
}
.toggle input.mobileToggle:checked + label:before {
  background: #2ecc71;
  -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.toggle input.mobileToggle:checked + label:after {
  left: 20px;
}

.inactiveMixin {
  content: "";
  position: absolute;
  display: block;
}
.beforeAnimation {
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
}
.afterAnimation {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
