.ProfileCard {
  width: 60%;
  /* margin-right: 30px; */
  margin-left: 50px;
}
.ProfileCard .content-container {
  display: flex;
  /* border: 1px solid blue; */
}
.profile-info h4 {
  font-size: 0.75em;
  margin-bottom: 3px;
  color: #3f37c9;
}
.profile-info span {
  font-size: 0.9em;
}
.account-section {
  margin: 50px 0;
}
.account-section h3 {
  font-weight: 500;
  color: #42447d;
  font-size: 1em;
  margin: 0;
}
.account-section span {
  font-size: 0.9em;
}
.account-section .account-subtitle {
  font-size: 0.85em;
  color: rgb(99, 99, 99);
  /* font-weight: 500; */
}
.notification-options {
  margin: 10px 0;
}
.ProfileCard .content-container {
  margin: 0px 0;
  /* border: 1px solid red; */
}
.account-info {
  margin-left: 10px;
}
.extra-info {
  font-size: 0.75em;
  color: #888;
}
.account-section .upgrade-subscription {
  display: block;
  font-size: 0.7em;
  width: fit-content;
  padding: 2px 5px;
  background-color: rgb(218, 253, 255);
}

.username-capitals {
  text-transform: capitalize;
}