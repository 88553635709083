.DevelopmentModal .modal-window {
  width: 700px;
  /* padding: 20px;s */
}
.dev-form {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.dev-form .field input {
  width: 200px;
}
.files {
  margin-top: -6px;
}
.multiple-files {
  font-size: 0.7em;
  color: #888;
}
.modal-content .long-field {
  margin-left: 50px;
}
.modal-content .long-field input {
  width: 200px;
}
.separator {
  width: 450px;
  height: 1px;
  margin: 20px auto;
  background-color: #c4b8f3;
}
.media-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.the-files {
  flex-basis: 50%;
  /* border: 1px solid red; */
}
.the-files svg {
  font-size: 0.8em;
  cursor: pointer;
  margin-right: 6px;
  /* color: #746df0 */
}
.the-files span {
  display: block;
}
.the-files a,
.the-files p {
  font-size: 0.7em;
  display: inline;
}
.files-container {
  height: 100px;
  overflow-y: scroll;
  background-color: #fcf8ff;
}
.modal-content textarea {
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: #3c4257;
  padding: 11px;
  width: 200px;
  /* width: 100%; */
  max-width: 100%;
  margin-top: 10px;
  resize: none;
}
.more-info {
  width: 400px;
  margin: 0 auto;
}
.more-info span {
  margin-right: 20px;
  color: gray;
  font-size: 0.7em;
  display: block;
}
